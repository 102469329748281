import React, { useEffect } from 'react';
import Layout from '../../../components/Learner/Layout';
import Authenticate from '../../../components/Identity/Authenticate';
import { viewedPage } from '../../../services/TrackActionService';
import { PageKind } from '../../../components/Learner/Services/LearnerNavigations';
import { LearnerProvider } from '../../../context/Learner/LearnerContext';
import BrowseAllCategoriesView from '../../../components/Learner/BrowseAllCategoriesView';

/**
 *
 *
 * @return {*}
 */
const Browse: React.FC = () => {
  useEffect(() => {
    viewedPage({
      pageKind: PageKind.BROWSE,
    });
  }, []);

  return (
    <LearnerProvider>
      <Layout>
        <BrowseAllCategoriesView />
      </Layout>
    </LearnerProvider>
  );
};

export default Authenticate(Browse);
