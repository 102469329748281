// extracted by mini-css-extract-plugin
export var filteredCoursesWrapper = "FilteredCoursesView-module--filteredCoursesWrapper--1uvv3";
export var backCta = "FilteredCoursesView-module--backCta--2C0Xg";
export var backArrowIcon = "FilteredCoursesView-module--backArrowIcon--3cHwk";
export var courseType = "FilteredCoursesView-module--courseType--3Mt9m";
export var filterInfoWrapper = "FilteredCoursesView-module--filterInfoWrapper--33OOV";
export var targetIcon = "FilteredCoursesView-module--targetIcon--1vSFD";
export var skillFilter = "FilteredCoursesView-module--skillFilter--1eZFj";
export var infoIcon = "FilteredCoursesView-module--infoIcon--22LRT";
export var verticalLine = "FilteredCoursesView-module--verticalLine--1ys8n";
export var courseCount = "FilteredCoursesView-module--courseCount--zJLTi";
export var courseCardList = "FilteredCoursesView-module--courseCardList--1hOuk";