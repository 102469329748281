import React from 'react';
import * as styles from '../../styles/learner/FilteredCoursesView.module.scss';
import { CourseTypes, DetailedCourseDisplay } from './BrowseAllCategoriesView';
import FirstCategoryCourseCard from './FirstCategoryCourseCard';
import PrivateCourseCard from './PrivateCourseCard';
import SecondCategoryCourseCard from './SecondCategoryCourseCard';
import { CoursesWithRegistrationInfo } from './Services/CourseService';
import ThirdCategoryCourseCard from './ThirdCategoryCourseCard';

type Props = {
  detailedCourseDisplay: DetailedCourseDisplay,
  enrollCourseHandler: (course: CoursesWithRegistrationInfo) => Promise<void>,
  onBack: () => void
}

const FilteredCoursesView: React.FC<Props> = ({
  detailedCourseDisplay,
  enrollCourseHandler,
  onBack,
}) => (
  <div className={styles.filteredCoursesWrapper}>
    <button
      id="learner-browse-back"
      type="button"
      className={styles.backCta}
      onClick={onBack}
    >
      <img
        src="/images/learner/back-arrow-icon.png"
        alt="Back arrow icon"
        className={styles.backArrowIcon}
      />
      Back
    </button>
    <h1 className={styles.courseType}>
      {detailedCourseDisplay.courseType}
    </h1>
    <div className={styles.filterInfoWrapper}>
      <img
        src="/images/learner/target-icon.png"
        className={styles.targetIcon}
        alt="Target Icon"
      />
      <h3 className={styles.skillFilter}>
        {detailedCourseDisplay.skillFilter}
      </h3>
      <div className={styles.verticalLine} />
      <p className={styles.courseCount}>{`${detailedCourseDisplay.filteredCourses.length} Courses`}</p>
    </div>
    {
      detailedCourseDisplay?.courseType === CourseTypes.ACCELERATED_COURSES
      && (
        <div className={styles.courseCardList}>
          {
            detailedCourseDisplay.filteredCourses.map((course) => (
              <FirstCategoryCourseCard
                key={course?.id}
                course={course}
                enrollCourseHandler={enrollCourseHandler}
                categoryType="Accelerator"
              />
            ))
          }
        </div>
      )
    }
    {
      detailedCourseDisplay?.courseType === CourseTypes.CERTIFIED_COURSES
      && (
      <div className={styles.courseCardList}>
        {
        detailedCourseDisplay.filteredCourses.map((course) => (
          <SecondCategoryCourseCard
            key={course?.id}
            course={course}
            enrollCourseHandler={enrollCourseHandler}
          />
        ))
      }
      </div>
      )
    }
    {
      detailedCourseDisplay?.courseType === CourseTypes.DIPLOMA_COURSES
      && (
        <div className={styles.courseCardList}>
          {
          detailedCourseDisplay.filteredCourses.map((course) => (
            <ThirdCategoryCourseCard
              key={course?.id}
              course={course}
              enrollCourseHandler={enrollCourseHandler}
            />
          ))
        }
        </div>
      )
    }
    {detailedCourseDisplay?.courseType === CourseTypes.PRIVATE_COURSES
       && (
       <div className={styles.courseCardList}>
         {
       detailedCourseDisplay.filteredCourses.map((course) => (
         <PrivateCourseCard
           key={course?.id}
           course={course}
           enrollCourseHandler={enrollCourseHandler}
         />
       ))
     }
       </div>
       )}

  </div>
);

export default FilteredCoursesView;
