import { Tabs } from 'antd';
import React, { useContext, useState } from 'react';
import BrowsePublicCourses from './BrowsePublicCourses';
import { CoursesWithRegistrationInfo } from './Services/CourseService';
import * as styles from '../../styles/learner/BrowseAllCategoriesView.module.scss';
import BrowsePrivateCourses from './BrowsePrivateCourses';
import LearnerContext from '../../context/Learner/LearnerContext';
import { truncateText } from '../../services/utils';

export enum BrowseView {
  LOADING = 'loading',
  ALL_COURSES = 'allCourses',
  FILTERED_COURSES = 'filteredCourses'
}

export enum CourseTypes {
  ACCELERATED_COURSES = 'Accelerated Courses',
  CERTIFIED_COURSES = 'Certified Courses',
  DIPLOMA_COURSES = 'Diploma Courses',
  PRIVATE_COURSES = 'Private Courses'
}

export type DetailedCourseDisplay = {
  skillFilter: string,
  filteredCourses: CoursesWithRegistrationInfo[],
  courseType: CourseTypes
}

const { TabPane } = Tabs;

const BrowseAllCategoriesView: React.FC = () => {
  const { enterpriseInfo } = useContext(LearnerContext);

  const [pageLoader, setPageLoader] = useState(true);
  const [hideTabs, setHideTabs] = useState(false);

  const pageLoaded = () => {
    setPageLoader(false);
  };

  const toggleTabsVisibility = () => {
    setHideTabs((prev) => (!prev));
  };

  const tabName = `${truncateText(enterpriseInfo?.name)}'s Courses`;
  const tabViewAvailable = !!enterpriseInfo?.scormCloudAppID;

  return (
    <div className={`${styles.browsePageContainer} ${pageLoader ? styles.loadingState : ''} ${
      hideTabs ? styles.hideTabs : ''}`}
    >
      <h3 className={styles.courseHeading}>
        Courses
      </h3>
      {tabViewAvailable && (
      <Tabs
        defaultActiveKey="learner-browse-public-courses"
        className={styles.tabs}
      >
        <TabPane tab="Upskillist Courses" key="learner-browse-public-courses">
          <BrowsePublicCourses
            pageLoaded={pageLoaded}
            toggleTabsVisibility={toggleTabsVisibility}
          />
        </TabPane>
        <TabPane tab={tabName} key="learner-browse-private-courses">
          <BrowsePrivateCourses toggleTabsVisibility={toggleTabsVisibility} />
        </TabPane>
      </Tabs>
      )}
      {!tabViewAvailable && (
        <BrowsePublicCourses
          pageLoaded={pageLoaded}
          toggleTabsVisibility={toggleTabsVisibility}
        />
      )}

    </div>
  );
};

export default BrowseAllCategoriesView;
