import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';
import { Input, Spin } from 'antd';
import * as styles from '../../styles/learner/BrowseAllCategoriesView.module.scss';
import { CoursesWithRegistrationInfo } from './Services/CourseService';
import FilteredCoursesView from './FilteredCoursesView';
import { BrowseView, CourseTypes } from './BrowseAllCategoriesView';
import { requestCourse } from './Services/CourseRegistrationService';
import LearnerContext from '../../context/Learner/LearnerContext';
import PrivateCourseCard from './PrivateCourseCard';
import { CourseAccessType } from '../../API';

const { Search } = Input;

type Props = {
  toggleTabsVisibility: () => void
}

const BrowsePrivateCourses:React.FC<Props> = ({ toggleTabsVisibility }) => {
  const {
    courseRegistrationsWithStatus,
    enterpriseInfo,
    setCourseRegistrationsWithStatus,
    setContextState,
    setCourseRegistrations,
    courseRegistrations,
    profileInfo,
  } = useContext(LearnerContext);

  const [allPrivateCourses, setAllPrivateCourses] = useState<
  CoursesWithRegistrationInfo[]>([] as CoursesWithRegistrationInfo[]);
  const [browseViewState, setBrowseViewState] = useState<BrowseView>(BrowseView.LOADING);
  const [searchTerm, setSearchTerm] = useState('');

  const filterCoursesBasedOnHiddenCourseIds = () => {
    const hiddenCourseIds = enterpriseInfo?.hiddenCourses || [];
    return courseRegistrationsWithStatus.filter((
      course: CoursesWithRegistrationInfo,
    ) => !hiddenCourseIds.includes(course?.id) && course?.accessType === CourseAccessType.PRIVATE);
  };

  const setAllowedPrivateCourses = () => {
    const allowedCourses = filterCoursesBasedOnHiddenCourseIds();
    setAllPrivateCourses(allowedCourses);
    setBrowseViewState(BrowseView.ALL_COURSES);
  };

  const filterCoursesBySearchTerm = () => {
    const coursesReducedBySearchTerm = allPrivateCourses.reduce(
      (acc: CoursesWithRegistrationInfo[],
        cur: CoursesWithRegistrationInfo) => {
        if (cur.courseName.toLowerCase().includes(searchTerm)) {
          acc.push(cur);
        }
        return acc;
      }, [],
    );
    return coursesReducedBySearchTerm;
  };

  const searchCourse: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const searchValue = e.target.value.trim()?.toLowerCase();
    setSearchTerm(searchValue);
  };

  const enrollCourseHandler = async (course: CoursesWithRegistrationInfo) => {
    setBrowseViewState(BrowseView.LOADING);
    await requestCourse(
      courseRegistrationsWithStatus,
      setCourseRegistrationsWithStatus,
      setContextState,
      setCourseRegistrations,
      courseRegistrations,
      profileInfo,
      course,
    );
    setBrowseViewState(BrowseView.LOADING);
  };

  const handleShowMore = () => {
    toggleTabsVisibility();
    setBrowseViewState(BrowseView.FILTERED_COURSES);
  };

  const onBack = () => {
    setBrowseViewState(BrowseView.ALL_COURSES);
    toggleTabsVisibility();
  };

  const filteredCourses = useMemo(() => {
    if (searchTerm) {
      return filterCoursesBySearchTerm();
    }
    return allPrivateCourses;
  }, [searchTerm, allPrivateCourses]);

  useEffect(() => {
    if (enterpriseInfo?.id) {
      setAllowedPrivateCourses();
    }
  }, [enterpriseInfo?.id, courseRegistrationsWithStatus]);

  if (browseViewState === BrowseView.LOADING) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.browse}>
      { browseViewState === BrowseView.ALL_COURSES
      && (
        <>
          <div className={styles.headingSection}>
            <div className={styles.filterSection}>
              <Search
                className={styles.search}
                placeholder="Search by course name"
                value={searchTerm}
                onChange={searchCourse}
              />
            </div>
          </div>
          <div className={styles.bodySection}>
            {
            filteredCourses?.length > 0
            && (
              <div className={styles.courseCategory}>
                <div className={styles.categoryHeader}>
                  <div className={styles.titleWrapper}>
                    <h1 className={styles.categoryTitle}>
                      {enterpriseInfo?.name}
                    </h1>
                    <div className={styles.verticalLine} />
                    <p className={styles.courseCount}>{`${filteredCourses.length} Courses`}</p>
                  </div>
                  <button
                    id="learner-browse-accelerator-courses-show-more"
                    type="button"
                    onClick={handleShowMore}
                  >
                    Show more
                  </button>
                </div>
                <div className={styles.courseCardList}>
                  {filteredCourses.slice(0, 8).map((course) => (
                    <PrivateCourseCard
                      key={course.id}
                      course={course}
                      enrollCourseHandler={enrollCourseHandler}
                    />
                  ))}
                </div>
              </div>
            )
          }
          </div>
        </>
      )}
      {
      browseViewState === BrowseView.FILTERED_COURSES
      && (
        <FilteredCoursesView
          detailedCourseDisplay={{
            filteredCourses,
            courseType: CourseTypes.PRIVATE_COURSES,
            skillFilter: 'All Courses',
          }}
          enrollCourseHandler={enrollCourseHandler}
          onBack={onBack}
        />
      )
      }
    </div>
  );
};

export default BrowsePrivateCourses;
