// extracted by mini-css-extract-plugin
export var card = "ThirdCategoryCourseCard-module--card--2VLS6";
export var courseType = "ThirdCategoryCourseCard-module--courseType--3QY_A";
export var courseTitleSection = "ThirdCategoryCourseCard-module--courseTitleSection--17GJo";
export var cardImage = "ThirdCategoryCourseCard-module--cardImage--1-83t";
export var courseDetails = "ThirdCategoryCourseCard-module--courseDetails--cTSAf";
export var courseTitle = "ThirdCategoryCourseCard-module--courseTitle--3sO9n";
export var moduleCount = "ThirdCategoryCourseCard-module--moduleCount--1H_AC";
export var clockIcon = "ThirdCategoryCourseCard-module--clockIcon--3oAdV";
export var cardCtaWrapper = "ThirdCategoryCourseCard-module--cardCtaWrapper--3CRjm";
export var enrollNowCta = "ThirdCategoryCourseCard-module--enrollNowCta--2PQC1";
export var continueLearningCta = "ThirdCategoryCourseCard-module--continueLearningCta--2AC0W";
export var moreInfoCta = "ThirdCategoryCourseCard-module--moreInfoCta--1zmBQ";
export var popover = "ThirdCategoryCourseCard-module--popover--Yp5pN";
export var hrLine = "ThirdCategoryCourseCard-module--hrLine--fQ2dP";
export var topSection = "ThirdCategoryCourseCard-module--topSection--1Pn-d";
export var wrapper = "ThirdCategoryCourseCard-module--wrapper--1JOrz";
export var title = "ThirdCategoryCourseCard-module--title--1Fn3x";
export var requestBtn = "ThirdCategoryCourseCard-module--requestBtn--2eIVd";
export var week = "ThirdCategoryCourseCard-module--week--22OW8";
export var skillLevel = "ThirdCategoryCourseCard-module--skillLevel--1D6jF";
export var bottomSection = "ThirdCategoryCourseCard-module--bottomSection--2WPfs";
export var verticalLine = "ThirdCategoryCourseCard-module--verticalLine--B1Y5W";
export var imageWrapper = "ThirdCategoryCourseCard-module--imageWrapper--3N7Rk";
export var browseCardPopover = "ThirdCategoryCourseCard-module--browseCardPopover--ZIq89";
export var hideOnMobile = "ThirdCategoryCourseCard-module--hideOnMobile--2VWoI";
export var showOnMobile = "ThirdCategoryCourseCard-module--showOnMobile--3N6o0";