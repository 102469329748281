// extracted by mini-css-extract-plugin
export var card = "SecondCategoryCourseCard-module--card--3zyMB";
export var courseType = "SecondCategoryCourseCard-module--courseType--1CQmP";
export var cardImage = "SecondCategoryCourseCard-module--cardImage--1d0Sr";
export var courseDetailsWrapper = "SecondCategoryCourseCard-module--courseDetailsWrapper--1Daik";
export var courseDetails = "SecondCategoryCourseCard-module--courseDetails--1Hp5L";
export var courseTitle = "SecondCategoryCourseCard-module--courseTitle--3K2uf";
export var moduleCount = "SecondCategoryCourseCard-module--moduleCount--37WYI";
export var clockIcon = "SecondCategoryCourseCard-module--clockIcon--3C445";
export var cardCtaWrapper = "SecondCategoryCourseCard-module--cardCtaWrapper--2Ci-X";
export var enrollNowCta = "SecondCategoryCourseCard-module--enrollNowCta--LkoUx";
export var continueLearningCta = "SecondCategoryCourseCard-module--continueLearningCta--3tFK1";
export var moreInfoCta = "SecondCategoryCourseCard-module--moreInfoCta--2dNES";
export var popover = "SecondCategoryCourseCard-module--popover--2YU4l";
export var hrLine = "SecondCategoryCourseCard-module--hrLine--2THCf";
export var topSection = "SecondCategoryCourseCard-module--topSection--1d54h";
export var wrapper = "SecondCategoryCourseCard-module--wrapper--NQKNG";
export var title = "SecondCategoryCourseCard-module--title--p6dAp";
export var requestBtn = "SecondCategoryCourseCard-module--requestBtn--2hrlg";
export var week = "SecondCategoryCourseCard-module--week--vvfEF";
export var skillLevel = "SecondCategoryCourseCard-module--skillLevel--2HrKh";
export var bottomSection = "SecondCategoryCourseCard-module--bottomSection--3rSVu";
export var verticalLine = "SecondCategoryCourseCard-module--verticalLine--1PAUk";
export var imageWrapper = "SecondCategoryCourseCard-module--imageWrapper--3x6s0";
export var browseCardPopover = "SecondCategoryCourseCard-module--browseCardPopover--25OoX";
export var hideOnMobile = "SecondCategoryCourseCard-module--hideOnMobile--lyFN0";
export var showOnMobile = "SecondCategoryCourseCard-module--showOnMobile--1N_Ze";