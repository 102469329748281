import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Input, Select, Spin } from 'antd';
import FilteredCoursesView from './FilteredCoursesView';
import FirstCategoryCourseCard from './FirstCategoryCourseCard';
import SecondCategoryCourseCard from './SecondCategoryCourseCard';
import ThirdCategoryCourseCard from './ThirdCategoryCourseCard';
import CourseService, { CoursesWithRegistrationInfo } from './Services/CourseService';
import { CourseType } from '../../models';
import LearnerContext from '../../context/Learner/LearnerContext';
import * as styles from '../../styles/learner/BrowseAllCategoriesView.module.scss';
import { requestCourse } from './Services/CourseRegistrationService';
import { BrowseView, CourseTypes, DetailedCourseDisplay } from './BrowseAllCategoriesView';
import { CourseAccessType } from '../../API';

const { Search } = Input;

const courseService = new CourseService();

type Skill = {
  name: string,
  selected: boolean,
}

type Props = {
  pageLoaded: () => void,
  toggleTabsVisibility: () => void,
}

const BrowsePublicCourses:React.FC<Props> = ({ pageLoaded, toggleTabsVisibility }) => {
  const [filteredCourses, setFilteredCourses] = useState<
  CoursesWithRegistrationInfo[]>([]);
  const [skillFilters, setSkillFilters] = useState<Skill[]>([]);
  const [browseViewState, setBrowseViewState] = useState<BrowseView>(BrowseView.LOADING);
  const [detailedCourseDisplay, setDetailedCourseDisplay] = useState<DetailedCourseDisplay>({
    skillFilter: '',
    filteredCourses: [],
    courseType: CourseTypes.ACCELERATED_COURSES,
  });
  const {
    courseRegistrationsWithStatus,
    enterpriseInfo,
    setCourseRegistrationsWithStatus,
    setContextState,
    setCourseRegistrations,
    courseRegistrations,
    profileInfo,
  } = useContext(LearnerContext);

  const filterCoursesBasedOnHiddenCourseIds = () => {
    const hiddenCourseIds = enterpriseInfo?.hiddenCourses || [];
    return courseRegistrationsWithStatus.filter((
      course: CoursesWithRegistrationInfo,
    ) => !hiddenCourseIds.includes(course?.id) && course.accessType === CourseAccessType.PUBLIC);
  };

  const setSkillsAndCoursesHandler = () => {
    const skills = courseService.getSkills();
    setSkillFilters(skills);
    filterCoursesBySelectedSkills(skills);
    setBrowseViewState(BrowseView.ALL_COURSES);
    pageLoaded();
  };

  const filterCoursesBySelectedSkills = (skills: Array<Skill>) => {
    const reducedCourses = courseService.filterCoursesBySelectedSkills(skills);
    const uniqueCourses = [...new Set(reducedCourses)];
    if (uniqueCourses?.length) {
      setFilteredCourses(uniqueCourses);
      return uniqueCourses;
    }

    const filteredCoursesBasedOnHiddenCourseIds = filterCoursesBasedOnHiddenCourseIds();
    setFilteredCourses(filteredCoursesBasedOnHiddenCourseIds);
    return filterCoursesBasedOnHiddenCourseIds;
  };

  const toggleIndividualSkill = (skill: string) => {
    const reducedSkills = skillFilters.reduce((acc: Skill[], cur: Skill) => {
      const currentSkill = cur;
      if (skill === 'All Courses') {
        currentSkill.selected = true;
      } else {
        // eslint-disable-next-line no-lonely-if
        if (skill === currentSkill.name) {
          currentSkill.selected = true;
        } else {
          currentSkill.selected = false;
        }
      }

      acc.push(currentSkill);
      return acc;
    }, []);

    setSkillFilters(reducedSkills);
    filterCoursesBySelectedSkills(reducedSkills);
  };

  const filterCoursesBySkill = (skill: string) => {
    toggleIndividualSkill(skill);
  };

  const filterCoursesBySearchTerm = (searchTerm: string) => {
    const coursesReducedBySearchTerm = courseService.filterCoursesBySearchTerm(searchTerm);
    setFilteredCourses(coursesReducedBySearchTerm);
  };

  const resetCourses = () => {
    filterCoursesBySelectedSkills(skillFilters);
  };

  const search: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const searchTerm = e.target.value.trim()?.toLowerCase();
    if (searchTerm) {
      // filterCourses by name or faculty
      filterCoursesBySearchTerm(searchTerm);
    } else {
      resetCourses();
    }
  };

  const getDropDownOptions = () => {
    const items = skillFilters.map((skill) => ({
      value: skill.name,
      label: skill.name,
    }));

    items.unshift({
      value: 'All Courses',
      label: 'All Courses',
    });

    return items;
  };

  const allAcceleratorCourses = useMemo(() => {
    const filteredAccCourses = filteredCourses.filter(
      (course) => course?.type === CourseType.ACCELERATOR_COURSE,
    );

    return filteredAccCourses;
  }, [filteredCourses]);

  const allCertifiedCourses = useMemo(() => {
    const filteredAccCourses = filteredCourses.filter(
      (course) => course?.type === CourseType.CERTIFIED_COURSE,
    );

    return filteredAccCourses;
  }, [filteredCourses]);

  const allDiplomaCourses = useMemo(() => {
    const filteredAccCourses = filteredCourses.filter(
      (course) => course?.type === CourseType.DIPLOMA_COURSE,
    );

    return filteredAccCourses;
  }, [filteredCourses]);

  const getSelectedSkill = () => {
    let selectedFilter = 'All Courses';
    if (!skillFilters.every((skill) => skill.selected)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      selectedFilter = skillFilters.find((skill) => skill.selected)!.name;
    }

    return selectedFilter;
  };

  const handleShowMore = (
    courseType: CourseTypes,
    courses: CoursesWithRegistrationInfo[],
  ) => {
    toggleTabsVisibility();
    setBrowseViewState(BrowseView.FILTERED_COURSES);
    const skillFilter = getSelectedSkill();

    setDetailedCourseDisplay({
      skillFilter,
      filteredCourses: courses,
      courseType,
    });
  };

  const onBack = () => {
    setBrowseViewState(BrowseView.ALL_COURSES);
    toggleTabsVisibility();
  };

  const enrollCourseHandler = async (course: CoursesWithRegistrationInfo) => {
    // State set twice to loading state, as the function request course updates the context
    // which re-renders the component leading to removal of the loading state.
    setBrowseViewState(BrowseView.LOADING);
    await requestCourse(
      courseRegistrationsWithStatus,
      setCourseRegistrationsWithStatus,
      setContextState,
      setCourseRegistrations,
      courseRegistrations,
      profileInfo,
      course,
    );
    setBrowseViewState(BrowseView.LOADING);
  };

  useEffect(() => {
    if (courseRegistrationsWithStatus?.length > 0 && enterpriseInfo?.id) {
      const filteredCoursesBasedOnHiddenCourseIds = filterCoursesBasedOnHiddenCourseIds();
      courseService.setCourses(filteredCoursesBasedOnHiddenCourseIds);
      setSkillsAndCoursesHandler();
    }
  }, [courseRegistrationsWithStatus, enterpriseInfo]);

  return (
    <>
      {
        browseViewState === BrowseView.LOADING
        && (
          <div className={styles.loading}>
            <Spin size="large" />
          </div>
        )
      }
      <div className={`${styles.browse} library-shaw-icon-new`}>
        {
    browseViewState === BrowseView.ALL_COURSES
    && (
      <>
        <div className={styles.headingSection}>
          <div className={styles.filterSection}>
            <Search
              className={styles.search}
              placeholder="Search by course name or skill"
              onChange={search}
            />
            <div className={styles.dropDownSkills}>
              <Select
                defaultValue="All Courses"
                value={getSelectedSkill()}
                style={{
                  width: 220,
                }}
                onChange={filterCoursesBySkill}
                options={getDropDownOptions()}
              />
            </div>
          </div>
        </div>
        <div className={styles.bodySection}>
          {
            allAcceleratorCourses?.length !== 0
            && (
              <div className={styles.courseCategory}>
                <div className={styles.categoryHeader}>
                  <div className={styles.titleWrapper}>
                    <h1 className={styles.categoryTitle}>
                      Accelerator Courses
                    </h1>
                    <div className={styles.verticalLine} />
                    <p className={styles.courseCount}>{`${allAcceleratorCourses.length} Courses`}</p>
                  </div>
                  <button
                    id="learner-browse-accelerator-courses-show-more"
                    type="button"
                    onClick={() => handleShowMore(
                      CourseTypes.ACCELERATED_COURSES, allAcceleratorCourses,
                    )}
                  >
                    Show more
                  </button>
                </div>
                <div className={styles.sectionInfo}>
                  <p>
                    Accelerator Courses are
                    {' '}
                    <strong>short</strong>
                    {' '}
                    courses that can be incorporated
                    into your busy schedule, consisting of 1 module with between
                    60-90 minutes of content.
                  </p>
                </div>

                <div className={styles.courseCardList}>
                  {allAcceleratorCourses.slice(0, 3).map((course) => (
                    <FirstCategoryCourseCard
                      key={course?.id}
                      course={course}
                      enrollCourseHandler={enrollCourseHandler}
                      categoryType="Accelerator"
                    />
                  ))}
                </div>
              </div>
            )
          }
          {
            allCertifiedCourses?.length !== 0
            && (
              <div className={styles.courseCategory}>
                <div className={styles.categoryHeader}>
                  <div className={styles.titleWrapper}>
                    <h1 className={styles.categoryTitle}>
                      Certified Courses
                    </h1>
                    <div className={styles.verticalLine} />
                    <p className={styles.courseCount}>{`${allCertifiedCourses.length} Courses`}</p>
                  </div>
                  <button
                    id="learner-browse-certified-courses-show-more"
                    type="button"
                    onClick={() => handleShowMore(
                      CourseTypes.CERTIFIED_COURSES, allCertifiedCourses,
                    )}
                  >
                    Show more
                  </button>
                </div>
                <div className={styles.sectionInfo}>
                  <p>
                    Certified Courses are courses that improve your knowledge
                    and skills in your role, consisting of
                    2-3 modules with between 4-16 hours of content.
                  </p>
                </div>
                <div className={styles.courseCardList}>
                  {allCertifiedCourses.slice(0, 4).map((course) => (
                    <SecondCategoryCourseCard
                      key={course?.id}
                      course={course}
                      enrollCourseHandler={enrollCourseHandler}
                    />
                  ))}
                </div>
              </div>
            )
          }
          {
            allDiplomaCourses?.length !== 0
            && (
              <div className={styles.courseCategory}>
                <div className={styles.categoryHeader}>
                  <div className={styles.titleWrapper}>
                    <h1 className={styles.categoryTitle}>
                      Diploma Courses
                    </h1>
                    <div className={styles.verticalLine} />
                    <p className={styles.courseCount}>{`${allDiplomaCourses.length} Courses`}</p>
                  </div>
                  <button
                    id="learner-browse-diploma-courses-show-more"
                    type="button"
                    onClick={() => handleShowMore(
                      CourseTypes.DIPLOMA_COURSES, allDiplomaCourses,
                    )}
                  >
                    Show more
                  </button>
                </div>
                <div className={styles.sectionInfo}>
                  <p>
                    Diploma Courses are
                    {' '}
                    <strong>in-depth</strong>
                    {' '}
                    courses that upskill
                    you both personally and professionally, consisting of 4
                    modules with between 16-32 hours of content.

                  </p>
                </div>
                <div className={styles.courseCardList}>
                  {allDiplomaCourses.slice(0, 3).map((course) => (
                    <ThirdCategoryCourseCard
                      key={course?.id}
                      course={course}
                      enrollCourseHandler={enrollCourseHandler}
                    />
                  ))}
                </div>
              </div>
            )
          }
        </div>
      </>
    )
  }
        {
    browseViewState === BrowseView.FILTERED_COURSES
    && (
      <FilteredCoursesView
        detailedCourseDisplay={detailedCourseDisplay}
        enrollCourseHandler={enrollCourseHandler}
        onBack={onBack}
      />
    )
  }
      </div>
    </>
  );
};

export default BrowsePublicCourses;
