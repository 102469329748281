import { navigate } from 'gatsby';
import React, { useContext } from 'react';
import LearnerContext from '../../context/Learner/LearnerContext';
import * as styles from '../../styles/learner/FirstCategoryCourseCard.module.scss';
import { CourseTypes } from './BrowseAllCategoriesView';
import { CoursesWithRegistrationInfo } from './Services/CourseService';

type Props = {
  course: CoursesWithRegistrationInfo,
  enrollCourseHandler: (course: CoursesWithRegistrationInfo) => Promise<void>,
  categoryType: string
}

const FirstCategoryCourseCard: React.FC<Props> = ({
  course,
  enrollCourseHandler,
  categoryType,
}) => {
  const {
    courseRegistrationsWithStatus,
  } = useContext(LearnerContext);

  const curCourseReg = courseRegistrationsWithStatus.filter(
    (courseReg: any) => courseReg.id === course.id,
  )[0];
  const courseNotEnrolled = !curCourseReg?.status;

  const moduleCount = course.modules.length;

  const moreInfoHandler = () => {
    navigate(`../course-details/${course.id}`);
  };

  const cardTagColor = () => {
    if (CourseTypes.CERTIFIED_COURSES.includes(categoryType)) {
      return { color: '#ed4c67' };
    }
    if (CourseTypes.DIPLOMA_COURSES.includes(categoryType)) {
      return { color: '#9c6af9' };
    }
    return {};
  };

  const handleCourseRegNavigation = () => {
    navigate(`../course-registration/${course?.registrationId}/content`);
  };

  return (
    <div
      className={styles.card}
    >
      <span
        className={styles.courseType}
        style={cardTagColor()}
      >
        {categoryType}

      </span>
      <div className={styles.courseTitleSection}>
        <img
          width={62}
          height={62}
          className={styles.cardImage}
          src={course?.courseTileImage?.href}
          alt={course?.courseTileImage?.title}
        />
        <div className={styles.courseDetails}>
          <h3 className={styles.courseTitle}>
            {course?.courseName}
          </h3>
          <p className={styles.moduleCount}>
            <img
              src="/images/learner/clock-icon.png"
              alt="Clock Icon"
              className={styles.clockIcon}
            />
            {`${moduleCount} ${moduleCount === 1 ? 'module' : 'modules'}`}
          </p>
        </div>
      </div>
      <div className={styles.cardCtaWrapper}>
        {
            courseNotEnrolled
              ? (
                <button
                  className={styles.enrollNowCta}
                  onClick={() => enrollCourseHandler(course)}
                  type="button"
                >
                  Enroll now
                </button>
              )
              : (
                <button
                  className={`${styles.enrollNowCta} ${styles.continueLearningCta}`}
                  onClick={handleCourseRegNavigation}
                  type="button"
                >
                  Continue Learning
                </button>
              )
          }
        <button
          className={styles.moreInfoCta}
          type="button"
          onClick={moreInfoHandler}
        >
          More Info
        </button>
      </div>
    </div>
  );
};

export default FirstCategoryCourseCard;
