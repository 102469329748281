// extracted by mini-css-extract-plugin
export var card = "PrivateCourseCard-module--card--2wYMS";
export var cardImage = "PrivateCourseCard-module--cardImage--2oGI7";
export var courseDetailsWrapper = "PrivateCourseCard-module--courseDetailsWrapper--1N3bd";
export var courseDetails = "PrivateCourseCard-module--courseDetails--2XlDr";
export var courseTitle = "PrivateCourseCard-module--courseTitle--2KVq1";
export var cardCtaWrapper = "PrivateCourseCard-module--cardCtaWrapper--1gIE5";
export var enrollNowCta = "PrivateCourseCard-module--enrollNowCta--3f8bU";
export var continueLearningCta = "PrivateCourseCard-module--continueLearningCta--1Xxj8";
export var moreInfoCta = "PrivateCourseCard-module--moreInfoCta--3uYfy";
export var popover = "PrivateCourseCard-module--popover--1ZhC3";
export var hrLine = "PrivateCourseCard-module--hrLine--S5OkV";
export var topSection = "PrivateCourseCard-module--topSection--3HHWL";
export var wrapper = "PrivateCourseCard-module--wrapper--q5nw9";
export var title = "PrivateCourseCard-module--title--31k7Z";
export var requestBtn = "PrivateCourseCard-module--requestBtn--2W9W2";
export var week = "PrivateCourseCard-module--week--22bKA";
export var skillLevel = "PrivateCourseCard-module--skillLevel--2JLeF";
export var bottomSection = "PrivateCourseCard-module--bottomSection--jpBHe";
export var verticalLine = "PrivateCourseCard-module--verticalLine--2uEg4";
export var imageWrapper = "PrivateCourseCard-module--imageWrapper--1M0Yq";
export var browseCardPopover = "PrivateCourseCard-module--browseCardPopover--vooWP";