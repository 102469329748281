// extracted by mini-css-extract-plugin
export var browsePageContainer = "BrowseAllCategoriesView-module--browsePageContainer--m3Vky";
export var courseHeading = "BrowseAllCategoriesView-module--courseHeading--2l0KN";
export var tabs = "BrowseAllCategoriesView-module--tabs--1H_Ne";
export var browse = "BrowseAllCategoriesView-module--browse--nMY3C";
export var headingSection = "BrowseAllCategoriesView-module--headingSection--3d96Z";
export var filterSection = "BrowseAllCategoriesView-module--filterSection--3d_sE";
export var search = "BrowseAllCategoriesView-module--search--25lI9";
export var antInputSearch = "BrowseAllCategoriesView-module--ant-input-search--A2OC2";
export var antInputGroup = "BrowseAllCategoriesView-module--ant-input-group--Azsua";
export var antInputGroupAddon = "BrowseAllCategoriesView-module--ant-input-group-addon--2hXVa";
export var dropDownSkills = "BrowseAllCategoriesView-module--dropDownSkills--1DI3F";
export var filterCta = "BrowseAllCategoriesView-module--filterCta--24Q5c";
export var dropDownArrow = "BrowseAllCategoriesView-module--dropDownArrow--1gmQ2";
export var groupBtn = "BrowseAllCategoriesView-module--groupBtn--rdgMK";
export var groupDropdown = "BrowseAllCategoriesView-module--groupDropdown--1e1IG";
export var appliedFilterSection = "BrowseAllCategoriesView-module--appliedFilterSection--3SubR";
export var filter = "BrowseAllCategoriesView-module--filter--2SdTl";
export var bodySection = "BrowseAllCategoriesView-module--bodySection--gI66q";
export var courseCategory = "BrowseAllCategoriesView-module--courseCategory--5hFDQ";
export var categoryHeader = "BrowseAllCategoriesView-module--categoryHeader--13eP6";
export var titleWrapper = "BrowseAllCategoriesView-module--titleWrapper--2K33r";
export var categoryTitle = "BrowseAllCategoriesView-module--categoryTitle--MG_HD";
export var infoIcon = "BrowseAllCategoriesView-module--infoIcon--2oNVG";
export var verticalLine = "BrowseAllCategoriesView-module--verticalLine--2pp9F";
export var courseCount = "BrowseAllCategoriesView-module--courseCount--30BTF";
export var sectionInfo = "BrowseAllCategoriesView-module--sectionInfo--18XQz";
export var courseCardList = "BrowseAllCategoriesView-module--courseCardList--2eDS3";
export var loadingState = "BrowseAllCategoriesView-module--loadingState--18EkX";
export var hideTabs = "BrowseAllCategoriesView-module--hideTabs--2Jr4o";
export var loading = "BrowseAllCategoriesView-module--loading--l7Gok";