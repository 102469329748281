// extracted by mini-css-extract-plugin
export var card = "FirstCategoryCourseCard-module--card--2Q47T";
export var courseType = "FirstCategoryCourseCard-module--courseType--3tlGf";
export var courseTitleSection = "FirstCategoryCourseCard-module--courseTitleSection--VcvVw";
export var cardImage = "FirstCategoryCourseCard-module--cardImage--nbaE2";
export var courseDetails = "FirstCategoryCourseCard-module--courseDetails--2jKYm";
export var courseTitle = "FirstCategoryCourseCard-module--courseTitle--1YrHG";
export var infoIcon = "FirstCategoryCourseCard-module--infoIcon--fSzB1";
export var moduleCount = "FirstCategoryCourseCard-module--moduleCount--1z7KF";
export var clockIcon = "FirstCategoryCourseCard-module--clockIcon--3ZW0k";
export var cardCtaWrapper = "FirstCategoryCourseCard-module--cardCtaWrapper--2KGtw";
export var enrollNowCta = "FirstCategoryCourseCard-module--enrollNowCta--NP5bX";
export var continueLearningCta = "FirstCategoryCourseCard-module--continueLearningCta--1A0k4";
export var moreInfoCta = "FirstCategoryCourseCard-module--moreInfoCta--2Wk_T";
export var popover = "FirstCategoryCourseCard-module--popover--1EWmk";
export var hrLine = "FirstCategoryCourseCard-module--hrLine--2emdr";
export var topSection = "FirstCategoryCourseCard-module--topSection--1YJQq";
export var wrapper = "FirstCategoryCourseCard-module--wrapper--D4dYh";
export var title = "FirstCategoryCourseCard-module--title--HT8Fe";
export var requestBtn = "FirstCategoryCourseCard-module--requestBtn--2NxbX";
export var week = "FirstCategoryCourseCard-module--week--1mEDm";
export var skillLevel = "FirstCategoryCourseCard-module--skillLevel--1mClk";
export var bottomSection = "FirstCategoryCourseCard-module--bottomSection--3c0o7";
export var verticalLine = "FirstCategoryCourseCard-module--verticalLine--3Ws6K";
export var imageWrapper = "FirstCategoryCourseCard-module--imageWrapper--2fVj8";
export var browseCardPopover = "FirstCategoryCourseCard-module--browseCardPopover--3N0jj";