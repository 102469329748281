import { navigate } from 'gatsby';
import React from 'react';
import { CourseRegistrationStatus } from '../../API';
import * as styles from '../../styles/learner/PrivateCourseCard.module.scss';
import { CoursesWithRegistrationInfo } from './Services/CourseService';

type Props = {
  course: CoursesWithRegistrationInfo,
  enrollCourseHandler: (course: CoursesWithRegistrationInfo) => Promise<void>,
}

const PrivateCourseCard:React.FC<Props> = ({ course, enrollCourseHandler }) => {
  const isCourseEnrolled = !course?.status || course?.status === CourseRegistrationStatus.ASSIGNED;

  const moreInfoHandler = () => {
    navigate(`../course-details/${course.id}`);
  };

  const handleCourseRegNavigation = () => {
    navigate(`../course-registration/${course?.registrationId}/content`);
  };

  return (
    <div
      className={`${styles.card} ${styles.hideOnMobile}`}
    >
      <div className={styles.cardImage}>
        <img
          src={course?.courseHeroBannerImage?.href}
          alt={course?.courseHeroBannerImage?.title}
        />
      </div>
      <div className={styles.courseDetailsWrapper}>
        <div className={styles.courseDetails}>
          <h3 className={styles.courseTitle}>
            {course?.courseName}
          </h3>
        </div>
        <div className={styles.cardCtaWrapper}>
          {
        isCourseEnrolled
          ? (
            <button
              className={styles.enrollNowCta}
              onClick={() => enrollCourseHandler(course)}
              type="button"
            >
              Enroll now
            </button>
          )
          : (
            <button
              className={`${styles.enrollNowCta} ${styles.continueLearningCta}`}
              onClick={handleCourseRegNavigation}
              type="button"
            >
              Continue Learning
            </button>
          )
      }
          <button
            className={styles.moreInfoCta}
            onClick={moreInfoHandler}
            type="button"
          >
            More Info
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrivateCourseCard;
